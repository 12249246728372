import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import api from './api/index'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import VueMeta from "vue-meta";

Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(api);
Vue.use(VueMeta);


new Vue({
  el:"#app",
  router,
  render: h => h(App)
})
