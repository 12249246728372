export default [

  {
    path: '/',
    component: () => import('../layout/layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/index.vue'),
      },
      {
        path: '/case',
        component: () => import('../views/case.vue'),
      },
      {
        path: '/case/info',
        component: () => import('../views/case/info.vue')
      },
      {
        path: '/about',
        component: () => import('../views/about.vue')
      },
      {
        path: '/system',
        component: () => import('../views/system.vue')
      }
    ]
  }
];