import ajax from './axios';

let api = {
    index: {
        index: () => {
            return ajax.post('index/index');
        },
        getHeader: () => {
            return ajax.post('index/getHeader');
        }
    },
    case: {
        list: param => {
            return ajax.post('Cases/list', param);
        },
        info: id => {
            return ajax.post('Cases/info', id);
        }
    },
}

export default {
    install: Vue => {
        Vue.api = api,
            Vue.prototype.$api = api
    }
}